:root {

  --primary-color: #020230;
  --primary1-color: #2d2d50;
  --primary2-color: #374151;
  /* --blue-primary-color: #3581BD; */
  /* --blue-primary-color: #18797F; */
  --blue-primary-color: #25cad2;
  --blue-light: #EBF4FA;
  --blue-primary1-color: #06a4d3;
  --blue-primary2-color: #14468A;
  --blue-primary3-color: #3a8ed0;
  --skyblue-color: #25CAD2;
  --gray-primary-color: #6b7280;
  --gray-primary1-color: #A1A1AA;
  --gray-primary2-color: #9CA3AF;
  --gray-primary3-color: #F9FAFB;
  --gray-primary4-color: #84818A;
  --gray-primary5-color: #505050;
  --gray-dark-color: #6c757d;
  --border-color: #E5E7EB;
  --border1-color: #dee2e6;
  --white-color: #ffffff;
  --white1-color: #F9FAFB;
  --white2-color: #F8FAFC;
  --text: #020230;  /* common on light and dark */
  --text2: #141619; 
  --text3: #2E2C34;
  --black: #000000;
  --black1: #0F172A;
  --black2: #111827;
  --black3: #333;
  --red: #e11d48;
  --red-light: #ffe4e6;
  --red-light2: #fda4af;
  --green: #2e8f3f;
  --green-light: #0D9488;
  --green-light1: #CCFBF1;
  --green-light2: #14b8a6;
  --green-light3: #5A7052;
  --green-light4: #DEF2D6;
  --orange: #D97706;
  --orange-light: #FEF3C7;
  --theme-text: #020230;
  
}

[data-theme=dark] {
  --theme-text: #fff;
}

/*** 

====================================================================
	Reset
====================================================================

***/
html {
  /* scroll-behavior: smooth; */
}

::-webkit-scrollbar {
  /* width: 12px; */
}
::-webkit-scrollbar-thumb {
  background: #020230;
  border-radius: 5px;
  border: 3px solid #fff;
}

::-webkit-scrollbar-track {
  background: #fff;
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-tel-input .special-label{
  display: none;
}
.signInput .react-tel-input .form-control {
  width: 100%;
}

/*** 
  ====================================================================
      Global Settings
  ====================================================================
   ***/
body {
  font-family: "Space Grotesk", sans-serif;
  font-weight: normal;
  font-size: 18px;
  /* line-height: 30px; */
  color: var(--theme-text);
  background: #f1f2f7;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
body.arabic * {
  direction: rtl;
  unicode-bidi: embed;
  font-family: 'Cairo', sans-serif !important;
  line-height: unset
}
body.arabic h2, body.arabic.h1 {
  line-height: 68px !important;
}


body.arabic .flip > *{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: "FlipH";
  -webkit-filter: FlipH;
  filter: FlipH;
  
}

body.arabic .app-phone-input *{
  direction: ltr;
}


body.infoAct {
  overflow: hidden;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--black);
}

.auto__container {
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 60px;
  width: 100%;
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  letter-spacing: -0.02em;
}
h1.example {
  text-align: center;
}

h2 {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  letter-spacing: -2px;
  color: var(--black3);
}

h3 {
  font-weight: 600;
  font-size: 34px;
  line-height: 32px;
  color: #374151;
}

h5 {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: var(--black3);
}

h6 {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black2);
}

p span {
  color: #3a8ed0;
}

.row{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}


.signInput__inner {
  position: relative;
}
.signInput__inner input {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  /* box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08); */
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary2-color);
  padding: 7px 8px;
}

.error__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--red);
  /* display: block; */
}

.clickable, .clickable-td{
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.clickable:hover{
  transition: all .3s ease-in-out;
  box-shadow: 0px 0px 2px 0px var(--gray-primary2-color);
}

.button {
  outline: none;
  height: 40px;
  padding: 0 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: unset;
  box-sizing: border-box;
  font-family: 'Inter';
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  font-weight: 500;
}

.button svg{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:focus {
  outline: none;
}

.button:hover {
  opacity: 0.8;
}

.button.primary {
  border: 1px solid #25CAD2;
  background-color: #25CAD2;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
}
.button.skyBlue{
  border: 1px solid var(--skyblue-color);
  background-color: var(--skyblue-color);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
  color: var(--white-color);
}

.button.white {
  background: #fff;
  color: #344054;
  border: 1px solid var(--border-color);
}

.button.gradient{
  background: linear-gradient(265.18deg, var(--skyblue-color) -0.01%, var(--blue-primary2-color) 99.01%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.button.transparent {
  border: 1px solid #667085;
  background-color: transparent;
  color: #fff;
}



.footer {
  background: #020230;
  /* padding: 60px 0 0 0; */
}
.footer__inner-logo {
  width: 112px;
  margin: 0 auto 32px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__inner-logo img {
  width: 100%;
}
.footer__inner-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
}
.footer__inner-nav-link {
  margin: 0 16px;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e4e7ec;
}



.header {
  /* padding: 25px 0;
  background: #020230;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; */
  padding: 25px 0;
  background: #020230;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__inner-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header__inner-logo img {
  height: 43px;
  width: 112px;
}
.header.sticky {
  position: fixed;
  animation: sticky 0.2s linear;
}

@-webkit-keyframes sticky {
  0% {
    top: -60px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes sticky {
  0% {
    top: -60px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.subnav:hover .subnav-content {
  
  transition: all .3s ease-in-out;
  /* opacity: 1; */
  visibility: visible;
}

.navbar a:hover, .subnav:hover .subnavbtn {
  background-color: var(--primary-color);
}
.nav__inner-link svg{
  transition: all .1s ease-in-out;
  
}
.navbar a:hover, .subnav:hover .nav__inner-link > svg{
  transform: rotate(-180deg);
  transition: all .1s ease-in-out;
}

.subnav-content svg{
  width: 40px;
  height: 40px;
}
.subnav-inner .nav__inner-link{
  margin: 0 !important;
}
.subnav-inner{
  display: flex;
  flex-wrap: wrap;
  background-color: var(--primary-color);
  padding: 30px;
  padding-top: 0;

}
.arabic .subnav-content {
  right: unset;
  left: 0;
}
.subnav-content {
  /* display: none; */
  /* opacity: 0; */
  /* height: 0; */
  visibility: hidden;
  position: absolute;
  right: 0px;
  width: 75%;
  z-index: 1;
  top: 30px;
  border-radius: 10px;
  transition: all .3s ease-in-out;
  border-top: 30px solid transparent;
}
.subnav-content a {
  color: var(--white-color);
  margin-right: 0;
}

.submenu-card-container > div{
  margin-inline-start: 20px;
}
.submenu-card-container {
  max-width: 311px;
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  padding: 31px;
}
.submenu-card-container h5{
  font-weight: 700;
  font-size: 18px;
  color: var(--white-color);
}

.submenu-card-container p{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--white-color);
}

.nav__inner {
  display: flex;
  align-items: center;
  /* position: relative; */
}
.nav__inner-link {
  display: block;
  margin-right: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #fff;
  cursor: pointer;
}
.nav__inner-lang {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #fff;
  cursor: pointer;
  margin-inline-start: 40px;
}
.nav__inner-lang-title {
  
  display: flex;
  align-items: center;
}
.nav__inner-lang-title img {
  width: 20px;
  height: 20px;
  margin-inline-end: 5px;
}
.nav__inner-lang-title span {
  display: block;
  margin-left: 5px;
  width: 12px;
  height: 8px;
  background: url(../public/images/icons/chevron-bottom.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.nav__inner-lang-content {
  position: absolute;
  top: 100%;
  right: 0;
  /* width: 100px; */
  background: #020230;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
.nav__inner-lang-content-link {
  padding: 10px;
  text-align: right;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
.nav__inner-lang-content-link:hover {
  opacity: 0.8;
}
.nav__inner-lang:hover .nav__inner-lang-content {
  max-height: 500px;
}
.nav__inner .button {
  margin-inline-start: 24px;
}

/* body.arabic .nav__inner-link {
  margin: 0 0 0 24px;
} */

/* body.arabic .nav__inner-lang-title span {
  margin: 0 5px 0 0;
} */
/* body.arabic .nav__inner .button {
  margin: 0 24px 0 0;
} */

.burger {
  display: none;
}


.copyright {
  padding: 32px 0;
  border-top: 1px solid #475467;
  display: flex;
  background: #020230;
  align-items: center;
  justify-content: space-between;
}
.copyright__text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}
.copyright .social-icons{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyright i{
  color: #fff;
  padding: 0 7px;
}
.copyright__nav {
  display: flex;
  align-items: center;
}
.copyright__nav-link {
  display: block;
  margin-left: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}

body.arabic .copyright__nav-link {
  margin: 0 16px 0 0;
}

.signCheck {
  position: relative;
  margin-bottom: 24px;
}

.sign.wider .sign__inner{
  /* max-width: 530px; */
  width: 770px;
}
.sign__inner .report-card:hover, .sign__inner .report-card.active{
  border-color: var(--skyblue-color);
  box-shadow: 0px 0px 2px 0px var(--skyblue-color);
}


.sign {
  min-height: calc(100vh - 93px - 89px);
  background: #020230;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.sign__inner {
  /* overflow-y: scroll; */
  /* height: 100%; */
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  box-sizing: border-box;
  margin: 0 auto;
  animation: 0.2s linear sign;
  width: 540px;
}
@-webkit-keyframes sign {
  0% {
    margin-top: 60px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes sign {
  0% {
    margin-top: 60px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.signInput {
  margin-bottom: 10px;
}
.signInput .pass-icon{
  color: var(--gray-primary2-color);
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  cursor: pointer;
  display: flex;
}

body.arabic .signInput .pass-icon{
  right: unset;
  left: 20px;
}
.signInput button {
  background: none;
}
.signInput .up {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 6.3px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.signInput .up svg {
  width: 100%;
}
.signInput .down {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 6.3px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.signInput .down svg {
  width: 100%;
}
.signInput__icon {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 52px; */
  height: 100%;
  background: #e5e7eb;
  border-right: 1px solid #e5e7eb;
  border-radius: 8px 0 0 8px;
  padding: 0 10px;
}
.signInput__icon svg {
  width: 16px;
  color: #9ca3af;
}
.signInput__name {
  /* position: absolute;
  top: 0;
  right: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 95px; */
  height: 100%;
  background: #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-radius: 0 8px 8px 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
  padding: 0 10px;
}
body.arabic .signInput.signInput-domain *:not(.signInput label){
  direction: ltr;
}
body.arabic .signCheck .checkbox label{
  top: 4px;
}
.signInput.signInput-domain .signInput__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  
  /* padding: 0 95px 0 52px; */
}
.signInput label {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}
.signInput__inner {
  position: relative;
}
.signInput__inner svg{
  width: 15px;
}
.signInput__inner input,select,textarea, .signInput__inner .inSelect {
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary2-color);
  padding: 5px 8px;
  box-sizing: border-box;
}
/* .signInput__inner .inSelect {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  padding: 5px 8px;
} */
.signInput__row {
  display: flex;
  justify-content: space-between;
}
.signInput__row.space24 {
  margin-bottom: 16px;
}
.signInput__row.space {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebeaed;
}
.signInput__col {
  width: calc(50% - 5px);
}
.signInput label, .label {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-primary-color);
  margin-bottom: 8px;
}
.signText {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary2-color);
  /* margin-bottom: 24px; */
  display: block;
}

.signOr {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 20px;
}

.signOr span {
  display: inline-block;
  padding: 0 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-primary2-color);
  background: var(--white-color);
  position: relative;
}
.signOr::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--gray-primary2-color);
}
.grecaptcha-badge{
  display: none !important;
}
.signMore {
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background: var(--white-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
  /* font-family: "Space Grotesk", sans-serif; */
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary2-color);
  height: 40px;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  letter-spacing: 0.25px;
 
}
.signMore img {
  margin-inline-end: 11px;
  width: 12.5px;
  height: 12.5px;
}

.sign .error {
  padding: 11px 18px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background: #ffe4e6;
  border: 1px solid #fda4af;
  border-radius: 4px;
  display: none;
}
.sign .error.active {
  display: flex;
}
.sign .error__text {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #e11d48;
}
.sign .error__icon {
  width: 20px;
  height: 20px;
  display: flex;
          align-items: center;
  margin-right: 16px;
          justify-content: center;
}
.sign .error svg {
  width: 100%;
  color: #e11d48;
}


.loader-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.loader-spin{
  animation: animName 4s linear infinite;
}

@keyframes animName {
 0%{
    transform: rotate(0deg);
   }
100%{
    transform: rotate(360deg);
   }
}


.init-loader-view .loader-container{
  height: 100%;
}
.init-loader-view{
  height: calc(100vh - 105px);
  /* padding-top: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
}

.progressbar-container{
  height: 10px;
  display: flex;
  align-items: center;
}
.progressbar-container .progressbar-step:last-child{
  margin-inline-end: 0;
}
.progressbar-step .progressbar-step-title span{
  margin-inline-end: 5px;
  border: 1px solid var(--black2);
  padding: 3px;
  border-radius: 100px;
  width:  12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
.progressbar-step .progressbar-step-title{
  display: flex;
  align-items: center;
  position: absolute;
  top: -35px;
  color: var(--black2);
  left: 2px;
  font-size: 13px;
  font-weight: 700;
}
.progressbar-container .progressbar-step{
  height: 100%;
  flex: 1;
  margin-inline-end: 5px;
  background-color: var(--gray-primary3-color);
  border-radius: 100px;
  transition: all .5s ease-in-out;
  position: relative;
}
.progressbar-container .progressbar-step.active{
  background-color: var(--blue-primary-color);
  transition: all .5s ease-in-out;
}


.checkbox {
  position: relative;
}
.checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.checkbox input:checked ~ label::before {
  border: 1px solid var(--skyblue-color);
  background: var(--skyblue-color);
}
.checkbox input:checked ~ label::after {
  opacity: 1;
}
.checkbox label {
  padding-left: 18px;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #374151;
}
.checkbox label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  transition: all 0.2s ease-in-out 0s;
  border-radius: 4px;
}
.checkbox label:after {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 8px;
  height: 4px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}




.report-cards-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.report-card.report-card-added{
  opacity: .8;
  position: relative;
  cursor: not-allowed;
}
.report-card.report-card-added .report-card-added-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-light2);
  border-top-left-radius: 5px;
}

.report-card-added-icon svg path{
  stroke: white;
}
.report-card-added-icon svg{
  height: 20px;
  width: 20px;
}
.report-card-added-icon {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
}
.report-card{
  width: calc(50% - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin: 10px;
  /* overflow: scroll; */
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  box-sizing: border-box;
}
.report-card .report-card-image img {
  width: 30px;
  height: 30px;
  align-self: center;

}

.report-card .report-card-image {
  width:  45px;
  height: 45px;
  border: 1px solid var(--border-color);
  border-radius: 1000px;
  margin-inline-end: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .report-card-body{
  flex: 1;
  height: 80px;
  overflow-y: scroll;
}

.report-card-body::-webkit-scrollbar {
  width: 1px;
}

.report-card-body::-webkit-scrollbar-thumb{
  border: unset;
} */

.report-card > div {
  font-family: 'Space Grotesk';
  color: var(--primary1-color);
}

.report-card h3{
  font-size: 18px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.report-card p {
  font-size: 12px;
  line-height: 15px;
}


.signHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--white1-color);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.signHeader hr {
  height: 2px;
  width: 100%;
  background: var(--green-light2);
  margin: 0 16px;
  border-radius: 5px;
}
.signHeader__step {
  background: var(--white1-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.signHeader__step.active .signHeader__step-number {
  background: var(--green-light2);
}
.signHeader__step.active span {
  color: var(--green-light2);
}
.signHeader__step-number {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: var(--primary1-color);
  color: var(--white-color);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--white-color);
  margin-inline-end: 16px;
}
.signHeader__step span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary1-color);
}
.signSubtitle {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 32px;
  color: var(--primary1-color);
}
.signTitle {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--primary2-color);
  /* margin-bottom: 10px; */
}
.signUniqTitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 4px;
  color: var(--blue-primary2-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signUniqTitle span {
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: var(--blue-primary2-color); 
}

.sign .numberInput::-webkit-inner-spin-button {
  opacity: 1;
}
.sign .splitInput {
  max-width: 280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px;
}
.sign .splitInput__input {
  position: relative;
  width: calc(16.6% - 4px);
  -webkit-box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  border: 1px solid var(--border-color);
}
.sign .splitInput input {
  background: var(--white-color);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary2-color);
  height: 36px;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  border: 3px solid transparent;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
.sign .splitInput input:focus {
  -webkit-box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.1);
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.1);
  /* border: 3px solid #c2dcf0; */
  border: 3px solid var(--blue-light);
}

@media (max-width: 968px) {
  .sign.wider .sign__inner{
    width: 100%;
  }
  .report-cards-container{
    flex-direction: column;
  }
  .report-card {
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: 760px) {
  .auto__container {
    padding: 0 16px;
  }

  .burger {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .burger::before {
    top: 20%;
    width: calc(100% - 0.4rem);
    height: 4px;
    background-color: #fff;
    border-radius: 0.125rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .burger::after {
    bottom: 20%;
    width: calc(100% - 0.4rem);
    height: 4px;
    background-color: #fff;
    border-radius: 0.125rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .burger.active::before {
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .burger.active::after {
    bottom: 50%;
    -webkit-transform: translate(-50%, 50%) rotate(-45deg);
    transform: translate(-50%, 50%) rotate(-45deg);
  }

  .nav {
    position: fixed;
    top: 72.7px;
    left: 0;
    width: 100%;
    height: calc(100% - 72.7px);
    z-index: 100;
    background: rgba(0, 0, 0, 0.4);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
  }
  .nav.active {
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .nav__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -ms-flex-direction: column;
    position: absolute;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    top: 0;
    right: 0;
    width: 250px;
    padding: 0;
    z-index: 1;
    padding: 20px;
    /* height: calc(100% - 40px); */
    height: calc(100%);
    overflow-y: auto;
    background: #020230;
  }
  .nav__inner-link {
    border: none;
    padding: 15px;
    display: block;
    text-align: left;
    width: unset;
    color: #fff;
    margin-right: 0;
    font-size: 16px;
    line-height: 1;
  }
  .nav__inner-lang {
    padding: 15px;
  }
  .nav__inner-lang-title {
    position: relative;
  }
  .nav__inner-lang-title span {
    position: absolute;
    top: 6px;
    right: 0;
  }
  .nav__inner-lang-content {
    position: unset;
  }
  .nav__inner-lang-content-link {
    padding: 10px 0 10px 25px;
  }
  .nav .button {
    margin: 5px 15px;
    text-align: center;
  }


  .footer {
    padding-top: 40px 0 0 0;
  }
  .footer__inner-nav {
    margin-bottom: 16px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .footer__inner-nav-link {
    margin: 16px;
  }

  .copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 24px 0;
  }
  .copyright__nav {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .copyright__text {
    margin-top: 15px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
  }
}

.keeplogin-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  
}

@media (max-width: 540px) {
  .sign__inner {
    width: 95%;
    padding: 20px;
  }
  .signInput__row{
    flex-direction: column;
  }
  .signInput__col{
    width: 100%;
    margin-bottom: 10px;
  }
  .social-btn{
    align-self: center;
    max-width: 202px;
    min-width: 202px;

  }
  .keeplogin-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.loader-data.active{
  /* height: fit-content; */
  /* visibility: visible;
  transition: .3s all ease-in-out; */
  /* z-index: 100; */
  display: flex;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 2s;
  
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.loader-data{
  margin-top: 70px;
  margin-bottom: 20px;
  display: none;
}

.loader-text{
  flex: 2;
}
.loader-text > div{
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
  color: #344054
}
.loader-text > p{
  font-size: 14px;
  color: rgb(165, 165, 165);
}